import { z } from "zod";

export function preprocessEmptyToNull<T extends z.ZodType>(schema: T): z.ZodEffects<T, z.infer<T> | null> {
    return z.preprocess((val) => {
        // Handle empty strings
        if (typeof val === 'string') {
            const trimmed = val.trim();
            return trimmed === '' ? null : trimmed;
        }
        // Handle NaN for numbers
        if (typeof val === 'number' && isNaN(val)) {
            return null;
        }
        // Handle undefined
        if (val === undefined) {
            return null;
        }
        return val;
    }, schema);
}